import { createTheme } from '@mui/material/styles';

const createCustomTheme = (primaryColor, secondaryColor) => {
  const theme = createTheme({
    typography: {
      fontFamily: ['"Sorts Mill Goudy"', 'serif'].join(','),
      h1: {
        fontWeight: 'bold',
        fontSize: '30px',
      },
      h3: {
        display:'block',
        fontWeight: 300,
        fontSize: '16px',
        lineHeight: '140%'
      },
      subtitle1: {
        fontWeight: 800,
        fontSize: '24px',
        lineHeight: '31.68px'
      },
      subtitle2: {
        fontWeight: 300,
        fontSize: '14px',
        lineHeight: '16.24px'
      }
    },
    shape: {
      borderRadius: 10,
    },
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor,
      },
    },
  });

  return theme;
};

export default createCustomTheme;