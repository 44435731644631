import React, { useEffect, useState, useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import Slider from "react-slick";
import AccountCircle from '@mui/icons-material/AccountCircle';
import logo from "./../../../assets/image/logo.png";
import img1 from "./../../../assets/image/sliders/home1.jpg";
import img2 from "./../../../assets/image/sliders/home2.jpg";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import img3 from "./../../../assets/image/sliders/home3.jpg";
import img4 from "./../../../assets/image/sliders/home4.jpg";
import img5 from "./../../../assets/image/sliders/home5.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GroupIcon from "@mui/icons-material/Group";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import SearchIcon from "@mui/icons-material/Search";
import { DateTime } from 'luxon';
import {
    List,
    ListItemButton,
    Toolbar,
    IconButton,
    Menu,
    MenuItem,
    Container,
    Button,
    ListItem,
    Drawer,
    ListItemText,
    InputBase,
    Popper,
    Paper,
    ClickAwayListener,
    MenuList,
    Grow,
    TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { Link, useNavigate, useLocation } from 'react-router-dom';
import { DatePicker } from "@mui/x-date-pickers";

const images = [img1, img2, img3, img4, img5];
const navItems = [
    {label: "Home", url: "/"},
    {label: "About Us", url: "/about-us"},
    {label: "List Your Property", url: "/list-your-property"},
    {label: "Properties", url: "/properties"}
  ];
const Header = ({ isLoggedIn, loggedCustomer, isSaved, cities, getProperty, properties }) => {
    const [isHome, seIstHome] = useState(false);
    const [isWrap, seIstWrap] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const ls = ["", "properties", "contact-us", "property", "property-search"];
    React.useEffect(() => {
        const loc = location.pathname.split("/");
        loc[1] == "" ? seIstHome(true) : seIstHome(false);
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [location]);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [fullScreen, setFullScreen] = useState(false);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const [anchorEl, setAnchorEl] = useState(null);
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [guestMenuOpen, setGuestMenuOpen] = useState(false);
    const [guestAnchorEl, setGuestAnchorEl] = useState(null);
    const [guestCount, setGuestCount] = useState(1);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedCity, setSelectedCity] = useState("");
    const [check, setCheck] = useState(false);
    const [bgColor, setBgColor] = useState("transparent");
    const [searchProperty, setSearchProperty] = useState({
        search: 'none',
        checkIn: null,
        checkout: null,
        guests: 'none'
      });
      const [anchorEll, setAnchorEll] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEll(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEll(null);
  };
    const navMenuRef = useRef();

    useEffect(() => {
        if (properties && properties.length > 0 && check) {
          let dateIn;
          let dateOut;
          if (searchProperty.checkIn !== null) {
            const luxonDateTime = DateTime.fromISO(searchProperty.checkIn);
            const formattedDate = luxonDateTime.toFormat('yyyy-MM-dd');
            dateIn = formattedDate;
          }
          if (searchProperty.checkout !== null) {
            const luxonDateTime = DateTime.fromISO(searchProperty.checkout);
            const formattedDate = luxonDateTime.toFormat('yyyy-MM-dd');
            dateOut = formattedDate;
          }
            navigate(`/property-search/${searchProperty.search}/${dateIn}/${dateOut}/${searchProperty.guests}`);
        }
      }, [properties]);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleSelectItem = (item) => {
        setSelectedItem(item);
        setMobileOpen(false); // Close drawer on item select
    };
    
    // useEffect(() => {
    //     const handleResize = () => {
    //         setScreenWidth(window.innerWidth);
    //     };

    //     window.addEventListener('resize', handleResize);
    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, []);

    // useEffect(() => {
    //     if (screenWidth > 900) {
    //         setIsMenuOpen(false);
    //     } else if (screenWidth < 600) {
    //         setFullScreen(true);
    //     } else if (screenWidth > 600) {
    //         setFullScreen(false);
    //     }
    // }, [screenWidth]);


    // useEffect(() => {
    //     const handleMenuClose = (e) => {
    //         if (!navMenuRef.current.contains(e.target)) {
    //             setIsMenuOpen(false);
    //         }
    //     }
    //     document.addEventListener('mousedown', handleMenuClose)

    //     return () => {
    //         document.removeEventListener('mousedown', handleMenuClose)
    //     }
    // })

    // const handleMenuClick = () => {
    //     setIsMenuOpen(!isMenuOpen);
    // };

    // const handleOpenUserMenu = (event) => {
    //     setAnchorElUser(event.currentTarget);
    // };
    // const handleCloseUserMenu = () => {
    //     setAnchorElUser(null);
    // };

    const handleCityClick = (event) => setAnchorEl(event.currentTarget);
    const handleCityClose = (city) => {
      setSelectedCity(city);
      setAnchorEl(null);
    };
  
    const handleDateChange = (newDate) => {
      setSelectedDate(newDate);
      setDatePickerOpen(false);
    };
  
    const handleGuestClick = (event) => {
      setGuestMenuOpen(true);
      setGuestAnchorEl(event.currentTarget);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        setCheck(true);
        let dateIn;
        let dateOut;

        if (searchProperty.checkIn !== null) {
            const luxonDateTime = DateTime.fromISO(searchProperty.checkIn);
            const formattedDate = luxonDateTime.toFormat('MM-dd-yyyy');
            dateIn = formattedDate;
        }

        if (searchProperty.checkout !== null) {
            const luxonDateTime = DateTime.fromISO(searchProperty.checkout);
            const formattedDate = luxonDateTime.toFormat('MM-dd-yyyy');
            dateOut = formattedDate;
        }
        getProperty({ ...searchProperty, checkIn: dateIn, checkout: dateOut })
    }

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setBgColor("#0D1B2A");
            } else {
                setBgColor("transparent");
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);
    return (
        <>
           <Box
    sx={{
    position: "relative",
    width: "100%",
    }}
>
    {/* Background Image Carousel */}
    {isHome && (
        <Slider
        className="slick-header"
        autoplay
        autoplaySpeed={5000}
        infinite
        dots={false}
        arrows={false}
        fade
        slidesToShow={1}
        slidesToScroll={1}
        >
        {images.map((img, index) => (
            <Box
            key={index}
            sx={{
                height: {xs: "90vh", sm: "100vh"},
                backgroundImage: `url(${img})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
            }}
            />
        ))}
        </Slider> 
    )}

    {/* Dark Overlay for better readability */}
    <Box
    sx={{
        position: isHome ? "absolute" : "relative",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        bgcolor: "rgba(0, 0, 0, 0.5)",
    }}
    />

    {/* Navbar */}
    <AppBar
    position="fixed"
    sx={{ background: isHome ? bgColor : "#0D1B2A", boxShadow: "none", py: {xs: "10px", md: "20px"} }}
>
    <Toolbar sx={{ justifyContent: "space-between" }}>
    <img 
        src={`${process.env.PUBLIC_URL}/images/image/logo.png`} 
        alt="Logo" 
        style={{ 
            width: "auto", 
            maxWidth: "250px", 
            height: "auto",
            maxHeight: "60px",
            cursor: "pointer" 
        }} 
        onClick={() => navigate("/")}
    />

    {/* Desktop Navigation */}
    <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
        {navItems.map((item) => (
            <Typography
                key={item}
                sx={{
                    color: "#fff",
                    textTransform: "none",
                    mx: 1,
                    fontFamily: "Inter",
                    fontSize: "18px",
                    cursor: "pointer",
                    fontWeight: selectedItem === item ? "bold" : "normal",
                    padding: item.bg ? "10px 20px" : "auto",
                    background: item.bg ? "#0D1B2A" : "transparent",
                    borderRadius: "20px"
                }}
                onClick={() => {
                    handleSelectItem(item);
                    navigate(item?.url);
                }}
            >
                {item?.label}
            </Typography>
        ))}
        <Typography
            sx={{
                color: "#fff",
                textTransform: "none",
                mx: 1,
                fontFamily: "Inter",
                fontSize: "18px",
                cursor: "pointer",
                fontWeight: "normal",
                padding:  "auto",
                background: "transparent",
                borderRadius: "20px"
            }}
            onClick={() => {
                window.location.href = "https://owner.jadecaps.com"
            }}
        >
            Property Owner
        </Typography>
        {!isLoggedIn && (
            <Typography
                sx={{
                    color: "#fff",
                    textTransform: "none",
                    mx: 1,
                    fontFamily: "Inter",
                    fontSize: "18px",
                    cursor: "pointer",
                    fontWeight: "normal",
                    padding:  "auto",
                    background: "transparent",
                    borderRadius: "20px"
                }}
                onClick={() => {
                    navigate("/login");
                }}
            >
                Login
            </Typography>
        )}
        <Typography
            sx={{
                color: "#fff",
                textTransform: "none",
                mx: 1,
                fontFamily: "Inter",
                fontSize: "18px",
                cursor: "pointer",
                fontWeight: "normal",
                padding: "10px 20px",
                background: "#0D1B2A",
                borderRadius: "20px"
            }}
            onClick={() => {
                navigate("/contact-us");
            }}
        >
            Contact Us
        </Typography>
        {isLoggedIn && (
            <div>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
            >
                <AccountCircle />
                <Typography sx={{ fontSize: "18px", fontFamily: "Inter" }}>&nbsp;{loggedCustomer?.firstName}</Typography>
            </IconButton>
            
            <Menu
                id="menu-appbar"
                anchorEl={anchorEll}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
                open={Boolean(anchorEll)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => navigate("/profile")}>Profile</MenuItem>
                <MenuItem onClick={() => navigate("/my-booking")}>My bookings</MenuItem>
                <MenuItem onClick={() => {
                    sessionStorage.removeItem("loggedCustomer");
                    sessionStorage.removeItem("customerToken");
                    window.location.reload();
                }}>Logout</MenuItem>
            </Menu>
            </div>
        )}
    </Box>

    {/* Mobile Menu Icon */}
    <IconButton
        edge="end"
        color="inherit"
        aria-label="menu"
        onClick={handleDrawerToggle}
        sx={{ display: { xs: "block", md: "none" }, color: "#fff" }}
    >
        <MenuIcon />
    </IconButton>
    </Toolbar>
</AppBar>

{/* Enhanced Mobile Drawer */}
<Drawer 
    anchor="right" 
    open={mobileOpen} 
    onClose={handleDrawerToggle}
    sx={{
        '& .MuiDrawer-paper': { 
            width: '75%', 
            maxWidth: '300px',
            bgcolor: "#0D1B2A",
            color: "#fff"
        },
    }}
>
    <List sx={{ pt: 2 }}>
        {/* Close button */}
        <ListItem sx={{ justifyContent: 'flex-end', mb: 2 }}>
            <IconButton onClick={handleDrawerToggle} sx={{ color: "#fff" }}>
                <CloseIcon />
            </IconButton>
        </ListItem>
        
        {/* Navigation Items */}
        {navItems.map((item, ind) => (
            <ListItem key={ind} disablePadding>
                <ListItemButton 
                    onClick={() => {
                        navigate(item?.url);
                        handleDrawerToggle();
                    }}
                    sx={{
                        py: 1.5,
                        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                    }}
                >
                    <ListItemText 
                        primary={item?.label} 
                        primaryTypographyProps={{
                            fontFamily: "Inter",
                            fontSize: "16px"
                        }}
                    />
                </ListItemButton>
            </ListItem>
        ))}
        
        {/* Property Owner Link */}
        <ListItem disablePadding>
            <ListItemButton 
                onClick={() => {
                    window.location.href = "https://owner.jadecaps.com";
                    handleDrawerToggle();
                }}
                sx={{
                    py: 1.5,
                    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                }}
            >
                <ListItemText 
                    primary="Property Owner" 
                    primaryTypographyProps={{
                        fontFamily: "Inter",
                        fontSize: "16px"
                    }}
                />
            </ListItemButton>
        </ListItem>
        
        {/* Contact Us Link */}
        <ListItem disablePadding>
            <ListItemButton 
                onClick={() => {
                    navigate("/contact-us");
                    handleDrawerToggle();
                }}
                sx={{
                    py: 1.5,
                    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                }}
            >
                <ListItemText 
                    primary="Contact Us" 
                    primaryTypographyProps={{
                        fontFamily: "Inter",
                        fontSize: "16px"
                    }}
                />
            </ListItemButton>
        </ListItem>
        
        {/* Login Link (only if not logged in) */}
        {!isLoggedIn && (
            <ListItem disablePadding>
                <ListItemButton 
                    onClick={() => {
                        navigate("/login");
                        handleDrawerToggle();
                    }}
                    sx={{
                        py: 1.5,
                        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                    }}
                >
                    <ListItemText 
                        primary="Login" 
                        primaryTypographyProps={{
                            fontFamily: "Inter",
                            fontSize: "16px"
                        }}
                    />
                </ListItemButton>
            </ListItem>
        )}
        
        {/* User Account Options (only if logged in) */}
        {isLoggedIn && (
            <>
                <ListItem disablePadding>
                    <ListItemButton 
                        onClick={() => {
                            navigate("/profile");
                            handleDrawerToggle();
                        }}
                        sx={{
                            py: 1.5,
                            borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                        }}
                    >
                        <ListItemText 
                            primary="Profile" 
                            primaryTypographyProps={{
                                fontFamily: "Inter",
                                fontSize: "16px"
                            }}
                        />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton 
                        onClick={() => {
                            navigate("/my-booking");
                            handleDrawerToggle();
                        }}
                        sx={{
                            py: 1.5,
                            borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                        }}
                    >
                        <ListItemText 
                            primary="My Bookings" 
                            primaryTypographyProps={{
                                fontFamily: "Inter",
                                fontSize: "16px"
                            }}
                        />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton 
                        onClick={() => {
                            sessionStorage.removeItem("loggedCustomer");
                            sessionStorage.removeItem("customerToken");
                            window.location.reload();
                            handleDrawerToggle();
                        }}
                        sx={{
                            py: 1.5,
                            borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                        }}
                    >
                        <ListItemText 
                            primary="Logout" 
                            primaryTypographyProps={{
                                fontFamily: "Inter",
                                fontSize: "16px"
                            }}
                        />
                    </ListItemButton>
                </ListItem>
            </>
        )}
    </List>
</Drawer>

    {/* Main Heading */}
    {isHome && (
    <Box
    sx={{
        position: "absolute",
        top: "25%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        color: "#fff",
        width: "90%",
        px: 2
    }}
    >
    <Typography 
        variant="h3" 
        fontSize={{xs: "32px", sm: "40px", md: "48px"}} 
        fontWeight="500"
    >
        BOOK YOUR RENTALS
    </Typography>
    <Typography 
        variant="subtitle1" 
        fontWeight="400" 
        fontSize={{xs: "14px", sm: "16px"}}
        sx={{ fontFamily: "Inter", mt: 1 }}
    >
        DISCOUNT UPTO 15% ON ALL PROPERTIES
    </Typography>
    </Box>
    )}

    {/* Search Box */}
    {isHome && (
        <Container maxWidth="md">
            <form onSubmit={(e) => handleSearch(e)} className="header-form">
                <Box
                    sx={{
                    position: "absolute",
                    bottom: {xs: "15%", md: "20%"},
                    left: "50%",
                    transform: "translateX(-50%)",
                    background: "rgb(255 255 255 / 75%)",
                    display: "flex",
                    flexDirection: {xs: "column", md: "row"},
                    alignItems: "center",
                    px: {xs: 1.5, md: 2},
                    py: {xs: 2, md: 1},
                    width: "90%",
                    maxWidth: "900px",
                    borderRadius: {xs: "25px", md: "50px"},
                    gap: {xs: 1.5, md: 0}
                    }}
                >
                    {/* Destination Input */}
                    <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        width: {xs: "100%", md: "auto"},
                        flex: {md: 1},
                        borderRight: {xs: "none", md: "1px solid #ccc"},
                        borderBottom: {xs: "1px solid #ccc", md: "none"},
                        pb: {xs: 1.5, md: 0},
                        pr: {xs: 0, md: 2},
                    }}
                    >
                    <LocationOnIcon sx={{ mr: 1 }} />
                    <TextField color="secondary"
                        sx={{ bgcolor: 'transparent', borderRadius: '10px', width: "100%" }}
                        select
                        fullWidth
                        defaultValue={'none'}
                        value={searchProperty.destination}
                        onChange={(e) =>
                            setSearchProperty({
                            ...searchProperty,
                            search: e.target.value,
                            }
                            )}
                        size="small"
                        >
                        <MenuItem value='none'>Destination</MenuItem>
                        {Array.isArray(cities) && cities.map((row) => (
                            <MenuItem key={row?.id} value={row?.id}>{row?.name || 'N/A'}</MenuItem>
                        ))}
                    </TextField>
                    </Box>

                    {/* Date Picker */}
                    <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: {xs: "column", sm: "row"},
                        width: {xs: "100%", md: "auto"},
                        flex: {md: 1},
                        gap: 1,
                        borderRight: {xs: "none", md: "1px solid #ccc"},
                        borderBottom: {xs: "1px solid #ccc", md: "none"},
                        pb: {xs: 1.5, md: 0},
                        px: {xs: 0, md: 2},
                    }}
                    >
                    <DemoContainer sx={{ padding: '0px', width: {xs: "100%", sm: "50%"} }} components={['DatePicker']}>
                    <Box sx={{ position: 'relative', width: "100%", display: "flex", alignItems: "center" }}>
                        <Box 
                            sx={{ 
                                display: { xs: 'flex', md: 'none' },  // Only show on mobile screens
                                mr: 1,
                                minWidth: "24px"
                            }}
                        >
                            <CalendarTodayIcon fontSize="small" color="action" />
                        </Box>
                        <DatePicker
                            value={searchProperty.checkIn}
                            timezone="UTC+0"
                            disablePast
                            onChange={(value) => {
                            setSearchProperty({
                                ...searchProperty,
                                checkIn: value,
                            });
                            }}
                            slotProps={{
                            textField: {
                                error: false,
                                size: 'small',
                                placeholder: 'Check in',
                                fullWidth: true
                            }
                            }}
                            sx={{
                                bgcolor: 'transparent',
                                borderColor: 'transparent',
                                width: "100%"
                            }}
                        />
                    </Box>
                    </DemoContainer>
                    <DemoContainer sx={{ padding: '0px', width: {xs: "100%", sm: "50%"} }} components={['DatePicker']}>
                        <Box sx={{ position: 'relative', width: "100%", display: "flex", alignItems: "center" }}>
                            <Box 
                                sx={{ 
                                    display: { xs: 'flex', md: 'none' },  // Only show on mobile screens
                                    mr: 1,
                                    minWidth: "24px"
                                }}
                            >
                                <CalendarTodayIcon fontSize="small" color="action" />
                            </Box>
                            <DatePicker
                                value={searchProperty.checkout}
                                timezone="UTC+0"
                                onChange={(value) =>
                                setSearchProperty({
                                    ...searchProperty,
                                    checkout: value,
                                }
                                )}
                                minDate={searchProperty.checkIn}
                                slotProps={{
                                textField: {
                                    error: false,
                                    size: 'small',
                                    placeholder: 'Check out',
                                    fullWidth: true
                                }
                                }}
                                sx={{
                                bgcolor: 'transparent',
                                width: "100%"
                                }}
                            />
                        </Box>
                        </DemoContainer>
                    </Box>

                    {/* Guests Selection */}
                    <Box
                    sx={{ 
                        display: "flex", 
                        alignItems: "center", 
                        width: {xs: "100%", md: "auto"},
                        flex: {md: 1}, 
                        px: {xs: 0, md: 2} 
                    }}
                    onClick={handleGuestClick}
                    >
                    <GroupIcon sx={{ mr: 1 }} />
                
                    <TextField
                        sx={{ 
                            bgcolor: 'transparent', 
                            borderRadius: '10px', 
                            border: 0,
                            width: "100%" 
                        }}
                        select
                        fullWidth
                        defaultValue={'none'}
                        value={searchProperty.guests}
                        onChange={(e) =>
                            setSearchProperty({
                            ...searchProperty,
                            guests: e.target.value,
                            }
                            )}
                        size="small"
                    >
                        <MenuItem value='none'>Guests</MenuItem>
                        {[...Array(30)].map((_, i) => (
                            <MenuItem key={i+1} value={(i+1).toString()}>{i+1}</MenuItem>
                        ))}
                    </TextField>
                    </Box>

                    {/* Search Button */}
                    <IconButton
                    type="submit"
                    sx={{
                        bgcolor: "#003366",
                        color: "#fff",
                        borderRadius: "50%",
                        ml: {xs: 0, md: 1},
                        alignSelf: {xs: "center", md: "center"},
                        mt: {xs: 1, md: 0},
                        mr: {xs: 1, md: 0}
                    }}
                    >
                    <SearchIcon />
                    </IconButton>
                </Box>
            </form>
        </Container>
    )}
</Box>
        </>
    )
}

export default Header
