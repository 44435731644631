import { useEffect, useState, } from 'react';
import { HashRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import createCustomTheme from './../theme/theme';
import store from './store';
import { Provider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { AppRootContainer } from './app-root/app-root.container';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../App.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";


function App() {


  const fetchThemeColors = () => {
    // Implement your logic to fetch theme colors from the database or external source
    return {
      primaryColor: '#0F2437', // Fetch primary color from the database
      secondaryColor: '#fafafa', // Fetch secondary color from the database
    };
  };
  
  const [themeColors, setThemeColors] = useState(null);
  
  useEffect(() => {
    const colors = fetchThemeColors();
    setThemeColors(colors);
  }, []);


   
  return (
    <Provider store={store}>
      <ThemeProvider theme={themeColors ? createCustomTheme(themeColors.primaryColor, themeColors.secondaryColor) : createCustomTheme("#ffffff", "#000000")}>
        {themeColors && <HashRouter>
          <ToastContainer />
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <AppRootContainer />
          </LocalizationProvider>
        </HashRouter>
        }
      </ThemeProvider>
    </Provider>
  );
}

export default App;
