export const CREATE_BOOKING = "CREATE_BOOKING";
export const CREATE_BOOKING_FAILURE = "CREATE_BOOKING_FAILURE";
export const CREATE_BOOKING_SUCCESS = "CREATE_BOOKING_SUCCESS";

export const CREATE_BOOKING_HDFC = "CREATE_BOOKING_HDFC";
export const CREATE_BOOKING_HDFC_FAILURE = "CREATE_BOOKING_HDFC_FAILURE";
export const CREATE_BOOKING_HDFC_SUCCESS = "CREATE_BOOKING_HDFC_SUCCESS";

export const APPLY_COUPON = "APPLY_COUPON";
export const APPLY_COUPON_FAILURE = "APPLY_COUPON_FAILURE";
export const APPLY_COUPON_SUCCESS = "APPLY_COUPON_SUCCESS";

export const CONFIRM_BOOKING = "CONFIRM_BOOKING";
export const CONFIRM_BOOKING_FAILURE = "CONFIRM_BOOKING_FAILURE";
export const CONFIRM_BOOKING_SUCCESS = "CONFIRM_BOOKING_SUCCESS";

export const FETCH_BOOKING = "FETCH_BOOKING";
export const FETCH_BOOKING_FAILURE = "FETCH_BOOKING_FAILURE";
export const FETCH_BOOKING_SUCCESS = "FETCH_BOOKING_SUCCESS";

export const CANCEL_BOOKING = "CANCEL_BOOKING";
export const CANCEL_BOOKING_FAILURE = "CANCEL_BOOKING_FAILURE";
export const CANCEL_BOOKING_SUCCESS = "CANCEL_BOOKING_SUCCESS";

export const GET_PRICES = "GET_PRICES";
export const GET_PRICES_FAILURE = "GET_PRICES_FAILURE";
export const GET_PRICES_SUCCESS = "GET_PRICES_SUCCESS";

export const CHECK_BOOKING = "CHECK_BOOKING";
export const CHECK_BOOKING_FAILURE = "CHECK_BOOKING_FAILURE";
export const CHECK_BOOKING_SUCCESS = "CHECK_BOOKING_SUCCESS";

export const SAVE_PROPERTY_FOR_NEXT = "SAVE_PROPERTY_FOR_NEXT";
export const REMOVE_PROPERTY_FOR_NEXT = "REMOVE_PROPERTY_FOR_NEXT";

export const REMOVE_COUPON = "REMOVE_COUPON";