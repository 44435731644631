import AppRoot from './app-root.component';
import { connect } from 'react-redux';
import { getProperty } from "./../../api/property/propertyAction";

const mapDispatchToProps = {
    getProperty
};

const mapStateToProps = state => ({
    isLoggedIn: state.customerPage.isLoggedIn,
    loggedCustomer: state.customerPage.loggedCustomer,
    isSaved: state.customerPage.isSaved,
    cities: state.cityPage.cities,
    properties: state.propertyPage.properties
});

export const AppRootContainer = connect(mapStateToProps, mapDispatchToProps)(AppRoot);