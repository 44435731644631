import React from 'react';
import { Box, Grid, Typography, Container, Divider, Card, CardMedia, IconButton } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom';
import { Instagram, YouTube, LinkedIn } from "@mui/icons-material";
import footerLogo from "./../../../assets/image/footer-logo.png";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
   <Box sx={{ bgcolor: "#0C1C2D", color: "white", py: { xs: 5, md: 14 } }}>
  <Container maxWidth="lg">
    <Grid container spacing={4} justifyContent="center">
      {/* Logo and Social Icons */}
      <Grid
        item
        xs={12}
        md={3}
        sx={{
          borderRight: { md: "2px solid #f2eeee", xs: "none" },
          textAlign: { xs: "center", md: "left" }
        }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/image/footer-logo.png`}
          alt="JadeCaps Logo"
          style={{ width: "150px", marginBottom: "15px" }}
        />
        <Box>
          <IconButton
            color="inherit"
            sx={{ mr: 1 }}
            href="https://www.youtube.com/channel/UCZzAxHIseSk-nepsA-HV_mw"
            target="_blank"
          >
            <YouTube />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.linkedin.com/company/jadecaps-technologies-pte-ltd/"
            target="_blank"
          >
            <LinkedIn />
          </IconButton>
        </Box>
      </Grid>

      {/* Address Section */}
      <Grid
        item
        xs={12}
        md={5}
        sx={{
          borderRight: { md: "2px solid #f2eeee", xs: "none" },
          textAlign: { xs: "center", md: "left" },
          mt: { xs: 3, md: 0 }
        }}
      >
        <Typography variant="h6" fontWeight="bold" sx={{ fontFamily: "inter" }}>
          Find Us Here
        </Typography>
        <Typography variant="body2" sx={{ fontFamily: "inter" }}>
          # 2nd Floor, 9, Sarjapur - Marathahalli Rd, 1st Block Koramangala, <br />
          Bengaluru, Karnataka 560034
        </Typography>
        <Typography variant="body2" sx={{ mt: "20px", fontFamily: "inter" }}>
          # 10 Anson Road, #10-11 International Plaza, Singapore 079903
        </Typography>
        <Typography variant="body2" sx={{ mt: "20px", fontFamily: "inter" }}>
          # 10 Komplek Nakula Plaza B-8, Jl. Nakula, Kuta, Badung, Bali
        </Typography>
        <Typography variant="body2" mt={2} sx={{ fontFamily: "inter" }}>
          +91 79816 09550
        </Typography>
        <Typography variant="body2">
          <Box
            component="a"
            href="mailto:customer@jadecaps.com"
            sx={{
              fontFamily: "inter",
              color: "#ffffff",
              textDecoration: "none",
              "&:hover": { color: "#c5c5c5" }
            }}
          >
            customer@jadecaps.com
          </Box>
        </Typography>
      </Grid>

      {/* Company Links */}
      <Grid
        item
        xs={6}
        md={2}
        textAlign={{ xs: "center", md: "left" }}
        sx={{ mt: { xs: 3, md: 0 } }}
      >
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ fontFamily: "inter" }}
        >
          Company
        </Typography>
        <Typography
          sx={{ cursor: "pointer", fontFamily: "inter" }}
          onClick={() => navigate("/")}
        >
          Home
        </Typography>
        <Typography
          sx={{ cursor: "pointer", fontFamily: "inter" }}
          onClick={() => navigate("/about-us")}
        >
          About Us
        </Typography>
        <Typography
          sx={{ cursor: "pointer", fontFamily: "inter" }}
          onClick={() => navigate("/our-team")}
        >
          Our Team
        </Typography>
        <Typography
          sx={{ cursor: "pointer", fontFamily: "inter" }}
          onClick={() => navigate("/contact-us")}
        >
          Contact
        </Typography>
      </Grid>

      {/* More Links */}
      <Grid
        item
        xs={6}
        md={2}
        textAlign={{ xs: "center", md: "left" }}
        sx={{ mt: { xs: 3, md: 0 } }}
      >
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ fontFamily: "inter" }}
        >
          More
        </Typography>
        <Typography
          sx={{ cursor: "pointer", fontFamily: "inter" }}
          onClick={() => navigate("/page/privacy-policy")}
        >
          Privacy Policy
        </Typography>
        <Typography
          sx={{ cursor: "pointer", fontFamily: "inter" }}
          onClick={() => navigate("/page/terms-and-conditions")}
        >
          Terms & Conditions
        </Typography>
        <Typography
          sx={{ cursor: "pointer", fontFamily: "inter" }}
          onClick={() => navigate("/page/refund-cancellation-policy")}
        >
          Cancellation
        </Typography>
      </Grid>
    </Grid>

    {/* Footer Bottom Text */}
    <Box mt={4} textAlign="center">
      <Typography
        variant="body2"
        sx={{
          fontFamily: "inter",
          color: "rgba(255, 255, 255, 0.8)"
        }}
      >
        © 2025 JadeCaps. All rights reserved.
      </Typography>
    </Box>
  </Container>
</Box>

    </>
  )
}

export default Footer

