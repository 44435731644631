export const CUSTOMER_FETCH = "CUSTOMER_FETCH";
export const CUSTOMER_FETCH_FAILURE = "CUSTOMER_FETCH_FAILURE";
export const CUSTOMER_FETCH_SUCCESS = "CUSTOMER_FETCH_SUCCESS";

export const CUSTOMER_UPDATE = "CUSTOMER_UPDATE";
export const CUSTOMER_UPDATE_FAILURE = "CUSTOMER_UPDATE_FAILURE";
export const CUSTOMER_UPDATE_SUCCESS = "CUSTOMER_UPDATE_SUCCESS";

export const CUSTOMER_LOGIN = "CUSTOMER_LOGIN";
export const CUSTOMER_LOGIN_FAILURE = "CUSTOMER_LOGIN_FAILURE";
export const CUSTOMER_LOGIN_SUCCESS = "CUSTOMER_LOGIN_SUCCESS";

export const CUSTOMER_REGISTER = "CUSTOMER_REGISTER";
export const CUSTOMER_REGISTER_FAILURE = "CUSTOMER_REGISTER_FAILURE";
export const CUSTOMER_REGISTER_SUCCESS = "CUSTOMER_REGISTER_SUCCESS";

export const CUSTOMER_FORGET = "CUSTOMER_FORGET";
export const CUSTOMER_FORGET_FAILURE = "CUSTOMER_FORGET_FAILURE";
export const CUSTOMER_FORGET_SUCCESS = "CUSTOMER_FORGET_SUCCESS";

export const CUSTOMER_FORGET_RESET = "CUSTOMER_FORGET_RESET";
export const CUSTOMER_FORGET_RESET_FAILURE = "CUSTOMER_FORGET_RESET_FAILURE";
export const CUSTOMER_FORGET_RESET_SUCCESS = "CUSTOMER_FORGET_RESET_SUCCESS";

export const CURRENT_CUSTOMER_FETCH = "CURRENT_CUSTOMER_FETCH";
export const CURRENT_CUSTOMER_FETCH_FAILURE = "CURRENT_CUSTOMER_FETCH_FAILURE";
export const CURRENT_CUSTOMER_FETCH_SUCCESS = "CURRENT_CUSTOMER_FETCH_SUCCESS";


export const UPDATE_PROFILE_DATA = "UPDATE_PROFILE_DATA";