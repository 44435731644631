import * as React from 'react';
import {Box, IconButton} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { RoutePath } from './../../routes';
import Footer from "./component/footer";
import Header from "./component/header";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// const InnerHeader = styled('div')(({ theme }) => ({
//   marginTop:'300px'
// }));

export default function AppRoot(props) {
  const { loggedCustomer, isLoggedIn, isSaved, cities, getProperty, properties } = props;
  return (
    <Box
      sx={{ overflow: 'hidden' }}
    >
      <CssBaseline />
      <Header key={loggedCustomer} loggedCustomer={loggedCustomer} isLoggedIn={isLoggedIn} isSaved={isSaved} cities={cities} getProperty={getProperty} properties={properties}/>
      <RoutePath />
      <Footer />
        <IconButton
          component="a"
          href="https://wa.me/919606592543?text=Hello! I'm interested to book your property."
          target="_blank"
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            backgroundColor: '#25d366',
            color: 'white',
            borderRadius: '50%',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            padding: '16px',
            zIndex: 1000,
          }}
        >
          <WhatsAppIcon />
        </IconButton>
    </Box>
  );
}