import * as actionTypes from "./propertyActionType";
import axios from 'axios';
import { checkError, apiTimeout } from './../error';
import { toast } from 'react-toastify';

const { REACT_APP_API_URL } = process.env;

export const getProperty = (params) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("adminToken")}`}};
    return (dispatch) => {
    dispatch(getPropertyInit());
    apiTimeout(axios.get(`${REACT_APP_API_URL}/property/consumer`,{params}))
    .then((response) => {
        if(response.data.data?.length === 0) {
            toast.info("No properties found for this city. Please try another location!", {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored"
            });
        }
        dispatch(getPropertySuccess(response.data.data));
    })
    .catch(function (error) {
        dispatch(getPropertyFailure());
        checkError(error);
    })
    };
};

export const getPropertyInit = () => ({
    type: actionTypes.GET_PROPERTY
});

export const getPropertyFailure = () => ({
    type: actionTypes.GET_PROPERTY_FAILURE
});

export const getPropertySuccess = data => ({
    type: actionTypes.GET_PROPERTY_SUCCESS,
    payload: { data }
});

export const getPropertyByTag = (params) => {
    return (dispatch) => {
    dispatch(getPropertyByTagInit());
    apiTimeout(axios.get(`${REACT_APP_API_URL}/property/by-tag`, {params}))
    .then((response) => {
        dispatch(getPropertyByTagSuccess(response.data.data));
    })
    .catch(function (error) {
        dispatch(getPropertyByTagFailure());
        checkError(error);
    })
    };
};

export const getPropertyByTagInit = () => ({
    type: actionTypes.GET_PROPERTY_BY_TAG
});

export const getPropertyByTagFailure = () => ({
    type: actionTypes.GET_PROPERTY_BY_TAG_FAILURE
});

export const getPropertyByTagSuccess = data => ({
    type: actionTypes.GET_PROPERTY_BY_TAG_SUCCESS,
    payload: { data }
});


export const getPropertyById = (id) => {
    return (dispatch) => {
    dispatch(getPropertyByIdInit());
    apiTimeout(axios.get(`${REACT_APP_API_URL}/property/${id}`))
    .then((response) => {
        dispatch(getPropertyByIdSuccess(response.data.data));
    })
    .catch(function (error) {
        dispatch(getPropertyByIdFailure());
        checkError(error);
    })
    };
};

export const getPropertyByIdInit = () => ({
    type: actionTypes.GET_PROPERTY_BY_ID
});

export const getPropertyByIdFailure = () => ({
    type: actionTypes.GET_PROPERTY_BY_ID_FAILURE
});

export const getPropertyByIdSuccess = data => ({
    type: actionTypes.GET_PROPERTY_BY_ID_SUCCESS,
    payload: { data }
});

