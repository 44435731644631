import { toast } from 'react-toastify';
export function checkError(err) {
    if (err.response.status === 401) {
        sessionStorage.removeItem('customerToken');
        sessionStorage.removeItem('loggedCustomer');
        // window.location.reload();
    } else if (err.response.status === 500) {
        // window.location.href = process.env.REACT_APP_WEB_URL + "/#/error-500";
    }
    toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored"
    });
}

export const apiTimeout = (promise) => {
    return new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        reject(new Error('Request timed out'));
      }, 30000);

      promise
        .then((response) => {
          clearTimeout(timeoutId);
          resolve(response);
        })
        .catch((error) => {
          clearTimeout(timeoutId);
          reject(error);
        });
    });
  };